import React, { useState } from "react";
import { graphql, Script } from "gatsby";
import Layout from "../../components/layout";
import Seo from "../../components/Seo";
import {SignupButton}  from "../../components/buttons/frontegg";
import FeaturesSection from "../../components/pricing/features";
import { pipesToSpan } from "../../util/common";
//import Arrow from "../images/drop_up_grey.svg";
//import PlansSection from "../../components/pricing/plans";
//import Switch from "../../components/buttons/switch";
import ParagraphFaq from "../../components/paragraphs/faq";
import ParagraphGenericBlock from "../../components/paragraphs/generic-block"

//const isProduction = `${process.env.GATSBY_ACTIVE_ENV}` === "production";

const PricingStripPage = ({ data :{node, terms }, ...props}) => {

  //console.log( props )
  //console.log( node )
  const { title2, description } = node;
  const { plans, features, faq, bottom } = node.relationships;


  const [isAnnual, setIsAnnual] = useState(true);


  const categories = terms.nodes.map((term) => ({
    category: term.name,
    items: features.filter(
      (item) => item.relationships.category.id === term.id
    ),
  }));

  const seo = { 
    meta: [],
    "title": "Pricing Strip | Sightfull", 
    "description": "The Sightfull platform helps SaaS companies leverage the full power of their business data in order to quickly analyze and optimize their revenue flows."
   };

  seo.meta.push({
    name: "robots",
    content: "noindex, nofollow",
  });

  //console.log("seo", seo);
  return (
    <Layout showCta={false}>
      <Seo {...seo} />
      <div className="h-[70px]"></div>

      <div
        // className={
        //   isProduction
        //     ? " bg-[url('/background/semantic-layer-hero-bckg.png')] bg-cover"
        //     : ""
        // }
      >
        {/* {isProduction && (
          <Hero
            title={title2}
            description={description}
            isAnnual={isAnnual}
            setIsAnnual={setIsAnnual}
          />
        )}
        {isProduction && (
          <PlansSection items={plans} isAnnual={isAnnual} features={features} />
        )} */}

        
          <Hero2
            title={title2}
            description={description}
            isAnnual={isAnnual}
            setIsAnnual={setIsAnnual}
          />
        
        
          <div className="container mx-auto py-12 space-y-4 mt-10">
            <Script
              async
              src="https://js.stripe.com/v3/pricing-table.js"
            ></Script>
            <stripe-pricing-table
              pricing-table-id="prctbl_1OZk7kKy9PAfj48T2KhvtThs"
              publishable-key="pk_live_51OZiDkKy9PAfj48TxnAcdVQD5gPQRvzT9Q8dzpKQOnpXwUilpSNbAya9tdKKAUJtAxfYPxcqiiI5MvDezKqM2PKE00AwlpRlqD"
            ></stripe-pricing-table>
          </div>
        
      </div>



      {/* <div className="container mx-auto py-12 space-y-4">
        <Script async src="https://js.stripe.com/v3/pricing-table.js"></Script>
        <stripe-pricing-table
          pricing-table-id="prctbl_1OZk7kKy9PAfj48T2KhvtThs"
          publishable-key="pk_live_51OZiDkKy9PAfj48TxnAcdVQD5gPQRvzT9Q8dzpKQOnpXwUilpSNbAya9tdKKAUJtAxfYPxcqiiI5MvDezKqM2PKE00AwlpRlqD"
        ></stripe-pricing-table>
      </div> */}

      <FeaturesSection
        items={plans}
        features={features}
        title="Complete feature list"
        categories={categories}
      />
      <Component data={faq} context="pricing" ></Component>
      <Component data={bottom} context="pricing" >
        {/* <Signup
                  title={bottom.cta.title}
                  className="border py-2.5 px-5 text-white rounded-full inline-block lg:mt-4  text-base font-medium bg-[#455468] rounded-[24px]"
                  type={"pricing"}
                  name={"cta"}
                />  */}

                <SignupButton
                className="border py-2.5 px-5 text-white rounded-full inline-block lg:mt-4  text-base font-medium bg-[#455468] rounded-[24px]"
                type={"pricing"}
                name={"cta"}
                >
                  {bottom.cta.title}
                </SignupButton>
      </Component>
    </Layout>
  );
};


// const Hero = ({ title, isAnnual, setIsAnnual }) => {
//   const t = pipesToSpan(title, "font-medium");
//   return (
//     <section className="pt-12 ">
//       <div className="container ">
//         <div className="space-y-4 lg:pr-6 text-center">
//           <h1
//             className=" text-[35px] lg:text-[70px] font-light leading-[120%] tracking-[-2.1px] font-readexpro"
//             dangerouslySetInnerHTML={{ __html: t }}
//           ></h1>

//           <div className="block pb-10 pt-6 mx-auto">
//             <div className="flex flex-row gap-x-4  items-center  justify-center">
//               <div className="">{"Monthly"}</div>
//               <div className="">
//                 <Switch
//                   isOn={isAnnual}
//                   handleToggle={(e) => setIsAnnual(e.target.checked)}
//                 />
//               </div>
//               <div className="">{"Annual billing (Save up to 18%)"}</div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

const Hero2 = ({ title }) => {
  const t = pipesToSpan(title, "font-semibold");
  return (
    <section className="pt-12 ">
      <div className="container ">
        <div className="space-y-4 lg:pr-6 text-center">
          <h1
            className="text-center  text-[40px] lg:text-[76px] font-semibold leading-[44px] lg:leading-[84px] lg:tracking-[-0.03em] text-[#3D434C]"
            dangerouslySetInnerHTML={{ __html: t }}
          ></h1>
        </div>
      </div>
    </section>
  );
};


const Component = ({ data, context, ...props }) => {
  //console.log( data )
  let comp = "";

  switch (data.__typename) {
    
    case "paragraph__generic_block":
      comp = <ParagraphGenericBlock data={data} context={context} {...props} />;
      break;

    case "paragraph__faq":
      comp = <ParagraphFaq data={data} context={context} {...props} />;
      break;

    default:
      comp = <pre>{JSON.stringify(data, null, 3)}</pre>;
      break;
  }

  return <>{comp}</>;
};


export default PricingStripPage;



export const query = graphql`
  query pricingStrip {
    node: nodePricing(id: {eq: "9b43a8cb-5616-5a13-a4eb-972a3b0e1c40"}) {
      ...NodePricing
    }
    terms: allTaxonomyTermPricing(sort: { fields: weight }) {
      nodes {
        __typename
        name
        id
      }
    }
  }
`;
